import { Redirect, Route, Switch } from 'react-router-dom';
import { retryLoad } from 'utils/loadableUtil';
import { useReactiveVar } from '@apollo/client';

import { isLoggedInVar } from './stores/adminUser';
import { initializeApp } from 'firebase/app';
import AdminRouter from 'router/AdminRouter';
import BizRouter from 'router/BizRouter';
import { getAuth } from 'firebase/auth';

const RegisterPage = retryLoad(
  () => import('./components/register/RegisterContainer'),
);
const SearchPasswordPage = retryLoad(
  () => import('./pages/user/SearchPasswordPage'),
);
const TranslateViewerPage = retryLoad(
  () => import('./pages/user/TranslateViewerPage'),
);
const LoginPage = retryLoad(() => import('./components/login/LoginContainer'));
const NotFoundPage = retryLoad(() => import('./pages/NotFoundPage'));
const CouponPage = retryLoad(() => import('./pages/user/CouponPage'));
const NoticePage = retryLoad(() => import('./pages/user/NoticePage'));

export const firebaseConfig = {
  apiKey: 'AIzaSyAiaLWrTpndfhm5z3bv66zgBtS-JJHsWqA',
  authDomain: 'dotetimer-test.firebaseapp.com',
  databaseURL: 'https://dotetimer-test.firebaseio.com',
  projectId: 'dotetimer-test',
  storageBucket: 'dotetimer-test.appspot.com',
  messagingSenderId: '669522614437',
  appId: '1:669522614437:web:32068e4e7bfbf65a04554c',
  measurementId: 'G-GMYRDPM187',
};
export const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);

const App = () => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);

  return (
    <Switch>
      {/* ✅ Public routes (로그인 필요 없음) */}
      <Route path="/register" component={RegisterPage} />
      <Route path="/login" component={LoginPage} />
      <Route path="/coupon" component={CouponPage} />
      <Route path="/notice" component={NoticePage} />
      <Route path="/search-password" component={SearchPasswordPage} />
      <Route path="/translate" component={TranslateViewerPage} />

      {/* ✅ 로그인 필요 */}
      {isLoggedIn ? (
        <>
          <Route path="/biz" component={BizRouter} />
          <Route path="/admin" component={AdminRouter} />
          <Route exact path="/" render={() => <Redirect to="/biz" />} />
        </>
      ) : (
        <>
          <Redirect to="/login" />
        </>
      )}

      {/* ✅ Not Found 페이지 */}
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default App;
