import * as React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Toolbar from '@mui/material/Toolbar';
import Collapse from '@mui/material/Collapse';
import MenuIcon from '@mui/icons-material/Menu';
import { IGroup } from 'components/admin/group/interface';

import NavbarFooter from 'components/navbar/NavbarFooter';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';

import { AppBar, Grid, IconButton, Typography } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';

const drawerWidth = 250;

interface Props {
  window?: () => Window;
  groups: IGroup[];
  groupIdx: number;
  setGroupIdx: React.Dispatch<React.SetStateAction<number>>;
}

export default function ResponsiveDrawer(props: Props) {
  const history = useHistory(); // ✅ useHistory 훅 사용

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [groupOpen, setGroupOpen] = React.useState(true);

  const handleGroupIdx = (idx: number) => {
    history.push(`/biz`);
    props.setGroupIdx(idx);
  };

  const handleGroupMenuClick = () => {
    setGroupOpen(!groupOpen);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Grid
      sx={{
        height: '100%',
        marginBottom: 20,
      }}
    >
      <Toolbar />
      <Divider />
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <List>
          <ListItemButton onClick={handleGroupMenuClick}>
            <ListItemIcon>
              <PeopleAltRoundedIcon />
            </ListItemIcon>
            <ListItemText primary={`그룹 (${props.groups.length}개)`} />
            {groupOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={groupOpen} timeout="auto" unmountOnExit>
            <List disablePadding>
              {props.groups.map((group: IGroup, i: number) => (
                <ListItemButton
                  key={group.id}
                  sx={{
                    pl: 4,
                    backgroundColor:
                      props.groupIdx === i
                        ? 'rgba(25, 118, 210, 0.2)'
                        : 'transparent', // ✅ 선택된 항목의 배경색 변경
                    '&:hover': {
                      backgroundColor:
                        props.groupIdx === i
                          ? 'rgba(25, 118, 210, 0.3)'
                          : 'rgba(0, 0, 0, 0.04)', // ✅ 호버 효과도 추가
                    },
                  }}
                  onClick={() => handleGroupIdx(i)}
                >
                  {props.groupIdx === i ? (
                    <ListItemText
                      primary={group.title}
                      sx={{ fontWeight: 'bold' }}
                    /> // ✅ 선택된 항목의 글자 강조
                  ) : (
                    <ListItemText secondary={group.title} />
                  )}
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        </List>
        <List>
          <ListItemButton
            component={Link}
            to="/biz/businessInput"
            sx={{ textAlign: 'center' }}
          >
            <ListItemText primary={`사업주 정보 입력`} />
          </ListItemButton>
          <ListItemButton
            component={Link}
            to="/biz/employeeList"
            sx={{ textAlign: 'center' }}
          >
            <ListItemText primary={`직원 목록`} />
          </ListItemButton>
          <ListItemButton
            component={Link}
            to="/biz/salaryLedgerList"
            sx={{ textAlign: 'center' }}
          >
            <ListItemText primary={`급여 대장 목록`} />
          </ListItemButton>
        </List>
      </List>
      <NavbarFooter />
    </Grid>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          background: '#1D2338',
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Doteteams
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
}
