import { useQuery, useReactiveVar } from '@apollo/client';
import BizTemplate from 'pages/biz/BizTemplate';
import { GET_MY_GROUPS } from 'pages/biz/gql';
import React, { useEffect, useMemo, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { retryLoad } from 'utils/loadableUtil';
import { GET_ADMIN_USER } from '../components/navbar/gql';
import { adminUserVar } from '../stores/adminUser';
import { useBizAccessLogger } from 'hooks/biz/useBizAccessLogger';

const BizPage = retryLoad(() => import('../pages/biz/BizPage'));
const BizCompanyPage = retryLoad(
  () => import('../pages/biz/company/BizCompanyPage'),
);

const SalaryLedgerListPage = retryLoad(
  () => import('../pages/biz/salary/BizSalaryLedgerPage'),
);

const EmployeeAddPage = retryLoad(
  () => import('../pages/biz/employee/EmployeeAddPage'),
);
const EmployeeEditPage = retryLoad(
  () => import('../pages/biz/employee/EmployeeEditPage'),
);

const EmployeeListPage = retryLoad(
  () => import('../pages/biz/employee/EmployeeListPage'),
);
const EmployeeDetailPage = retryLoad(
  () => import('../pages/biz/employee/EmployeeDetailPage'),
);

const BizEmployeeContractCreatePage = retryLoad(
  () => import('../pages/biz/contract/BizEmployeeContractCreatePage'),
);
const BizEmployeeContractPage = retryLoad(
  () => import('../pages/biz/contract/BizEmployeeContractPage'),
);
const BizEmployeeSalaryPage = retryLoad(
  () => import('../pages/biz/salary/BizEmployeeSalaryPage'),
);
const BizEmployeeSalaryUpdatePage = retryLoad(
  () => import('../pages/biz/salary/BizEmployeeSalaryUpdatePage'),
);
const BizUserPage = retryLoad(() => import('../pages/biz/BizUserPage'));

const BizRouter = () => {
  const [groupIdx, setGroupIdx] = useState<number>(0);
  const location = useLocation();
  const adminUser = useReactiveVar(adminUserVar);
  const logAccess = useBizAccessLogger();

  useEffect(() => {
    if (location.pathname.startsWith('/biz') && adminUser?.accountId) {
      logAccess({
        accountId: adminUser.accountId,
        isAdmin: adminUser.role.isAdmin,
      });
    }
  }, [location.pathname, adminUser]);

  // ✅ AdminUser 정보 가져오기
  useQuery(GET_ADMIN_USER, {
    onCompleted: (data) => {
      adminUserVar(data.BoardUserGetInfo);
    },
    fetchPolicy: 'no-cache',
  });

  // ✅ 내 그룹 정보 가져오기
  const { data } = useQuery(GET_MY_GROUPS, {
    fetchPolicy: 'cache-first', // ✅ 캐싱된 데이터를 먼저 사용
  });

  // ✅ groups를 useMemo로 최적화하여 불필요한 재생성을 방지
  const groups = useMemo(() => data?.GroupMyGetList.items || [], [data]);

  // ✅ URL에서 groupIdx 값을 가져오기
  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const groupIdxFromQuery = queryParams.get('groupIdx');

    if (groupIdxFromQuery) {
      setGroupIdx(parseInt(groupIdxFromQuery, 10)); // ✅ Query String 값 반영
    }
  }, [location.search]);

  return (
    <BizTemplate groups={groups} groupIdx={groupIdx} setGroupIdx={setGroupIdx}>
      <Switch>
        <Route
          exact
          path="/biz"
          render={(props) => <BizPage {...props} group={groups[groupIdx]} />}
        />

        <Route
          exact
          path="/biz/businessInput"
          render={(props) => (
            <BizCompanyPage {...props} group={groups[groupIdx]} />
          )}
        />
        <Route
          exact
          path="/biz/employeeList"
          render={(props) => (
            <EmployeeListPage {...props} group={groups[groupIdx]} />
          )}
        />

        <Route exact path="/biz/employee" component={EmployeeDetailPage} />
        <Route exact path="/biz/employee/add" component={EmployeeAddPage} />
        <Route exact path="/biz/employee/edit" component={EmployeeEditPage} />
        <Route
          exact
          path="/biz/employee/:id/contract/create"
          component={BizEmployeeContractCreatePage}
        />
        <Route
          exact
          path="/biz/employee/:id/contract"
          component={BizEmployeeContractPage}
        />
        <Route
          exact
          path="/biz/employee/:id/salary/"
          component={BizEmployeeSalaryPage}
        />
        <Route
          exact
          path="/biz/employee/:id/salary/:yearMonth"
          component={BizEmployeeSalaryUpdatePage}
        />
        <Route
          exact
          path="/biz/salaryLedgerList"
          render={(props) => (
            <SalaryLedgerListPage {...props} group={groups[groupIdx]} />
          )}
        />
        <Route
          exact
          path="/biz/:groupId/:userId"
          render={() => <BizUserPage />}
        />
      </Switch>
    </BizTemplate>
  );
};

export default BizRouter;
