import axios from 'axios';
import { getToken } from 'utils/JWT';

interface BizAccessLogParams {
  accountId: string;
  isAdmin: boolean;
}

const PATH = process.env.REACT_APP_GRAPHQL!.replace('/graphql', '/app/bizLog');

export function useBizAccessLogger() {
  return async ({ accountId, isAdmin }: BizAccessLogParams) => {
    try {
      const pathname = window.location.pathname;
      const nowUrl = `${window.location.origin}${pathname}`;

      const rawReferrer = document.referrer;
      let referrer: string;

      if (!rawReferrer) {
        referrer = 'Directly';
      } else {
        const currentOrigin = window.location.origin;
        const referrerOrigin = new URL(rawReferrer).origin;
        referrer = currentOrigin === referrerOrigin ? '' : rawReferrer;
      }

      const userAgent = navigator.userAgent;

      await axios.post(
        PATH,
        {
          path: nowUrl,
          accountId,
          isAdmin,
          referrer,
          userAgent,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            'Content-Type': 'application/json',
          },
        },
      );
    } catch (err) {
      console.error('[접속 로그 전송 실패]', err);
    }
  };
}
