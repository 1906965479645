import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ResponsiveDrawer from './ResponsiveDrawer';
import { IGroup } from 'components/admin/group/interface';

const drawerWidth = 250;

interface Props extends RouteComponentProps {
  children: React.ReactNode;
  groups: IGroup[];
  groupIdx: number;
  setGroupIdx: React.Dispatch<React.SetStateAction<number>>;
}

function BizTemplate({ children, groups, groupIdx, setGroupIdx }: Props) {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <ResponsiveDrawer
        groups={groups}
        groupIdx={groupIdx}
        setGroupIdx={setGroupIdx}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { md: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}

export default withRouter(BizTemplate);
