const setToken = (token: string) => {
  localStorage.setItem('token', token);
};

const getToken = () => {
  return localStorage.getItem('token');
};

const removeToken = () => {
  localStorage.removeItem('token');
};

const setTimerUserToken = (token: string) => {
  localStorage.setItem('timerUserToken', token);
};

const getTimerUserToken = () => {
  return localStorage.getItem('timerUserToken');
};

export {
  setToken,
  getToken,
  removeToken,
  setTimerUserToken,
  getTimerUserToken,
};
