import AdminTemplate from 'components/admin/AdminTemplate';
import { Route, Switch, Redirect } from 'react-router-dom';
import { retryLoad } from 'utils/loadableUtil';

const AdminUserPage = retryLoad(() => import('../pages/admin/UserPage'));
const AdminGoalPage = retryLoad(() => import('../pages/admin/GoalPage'));
const AdminTodoPage = retryLoad(() => import('../pages/admin/TodoPage'));
const AdminCoinLogPage = retryLoad(() => import('../pages/admin/CoinLogPage'));
const AdminDonatePage = retryLoad(() => import('../pages/admin/DonatePage'));
const AdminRankingPage = retryLoad(() => import('../pages/admin/RankingPage'));
const AdminWBNBoardPage = retryLoad(
  () => import('../pages/admin/WBNBoardPage'),
);
const AdminGroupPage = retryLoad(() => import('../pages/admin/GroupPage'));
const AdminGroupPostPage = retryLoad(
  () => import('../pages/admin/GroupPostPage'),
);
const AdminGroupPostImagePage = retryLoad(
  () => import('../pages/admin/GroupPostImagePage'),
);
const AdminPremiumLogPage = retryLoad(
  () => import('../pages/admin/PremiumLogPage'),
);
const AdminCouponPage = retryLoad(() => import('../pages/admin/CouponPage'));

const AdminConversionRatePage = retryLoad(
  () => import('../pages/admin/ConversionRatePage'),
);

const AdminRolePage = retryLoad(() => import('../pages/admin/RolePage'));
const AdminPermissionPage = retryLoad(
  () => import('../pages/admin/PermissionPage'),
);
const AdminBoardUserPage = retryLoad(() => import('pages/admin/BoardUserPage'));
const AdminMailPage = retryLoad(() => import('../pages/admin/AdminEmailPage'));
const AdminMailLogPage = retryLoad(() => import('../pages/admin/MailLogPage'));
const AdminMailTemplatePage = retryLoad(
  () => import('../pages/admin/MailTemplatePage'),
);
const AdminBannerPage = retryLoad(() => import('../pages/admin/BannerPage'));

const TranslationPage = retryLoad(
  () => import('../pages/admin/TranslationPage'),
);
const AdminSubscriptionPage = retryLoad(
  () => import('../pages/admin/SubscriptionPage'),
);
const AdminBizLogPage = retryLoad(() => import('../pages/admin/BizLogPage'));

const AdminRouter = () => {
  return (
    <AdminTemplate>
      <Switch>
        <Route
          component={() => <Redirect to="/admin/users/stats" />}
          path="/admin"
          exact
        />

        <Route component={AdminUserPage} path="/admin/users" />
        <Route component={AdminTodoPage} path="/admin/todos" />
        <Route component={AdminGoalPage} path="/admin/goals" />
        <Route component={AdminCoinLogPage} path="/admin/coinLogs" />
        <Route component={AdminDonatePage} path="/admin/donate" />
        <Route component={AdminMailPage} path="/admin/mail" />
        <Route component={AdminCouponPage} path="/admin/coupon" />
        <Route component={AdminPremiumLogPage} path="/admin/premiumLog" />
        <Route
          component={AdminConversionRatePage}
          path="/admin/conversionRate"
        />
        <Route component={AdminRankingPage} path="/admin/ranking" />
        <Route component={AdminSubscriptionPage} path="/admin/subscription" />
        <Route component={AdminRolePage} path="/admin/role" />
        <Route component={AdminPermissionPage} path="/admin/permission" />
        <Route component={AdminBoardUserPage} path="/admin/board-user" />
        <Route component={AdminWBNBoardPage} path="/admin/wbn" />
        <Route component={AdminGroupPage} path="/admin/group" />
        <Route component={AdminGroupPostPage} path="/admin/groupPost" />
        <Route
          component={AdminGroupPostImagePage}
          path="/admin/groupPostImage"
        />
        <Route component={AdminMailLogPage} path="/admin/mailLogs" />
        <Route component={AdminMailTemplatePage} path="/admin/mailTemplate" />
        <Route component={AdminBannerPage} path="/admin/banner" />
        <Route component={TranslationPage} path="/admin/translation" />
        <Route component={AdminBizLogPage} path="/admin/bizLog" />
      </Switch>
    </AdminTemplate>
  );
};

export default AdminRouter;
