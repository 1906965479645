import { gql } from 'graphql-tag';

export const GET_MY_GROUPS = gql`
  query GroupMyGetList {
    GroupMyGetList {
      items {
        id
        title
        description
        maximum
        currentNumberOfMember
        private
        password
        groupTheme {
          thumbnailImage
        }
        groupCategory {
          bigClass
          subClass
        }
        langCategory {
          title
        }
        createdAt
        promotedAt
      }
    }
  }
`;

export const GET_MY_GROUP_USERS = gql`
  query GroupUsers($groupId: Int!) {
    GroupUsers(groupId: $groupId) {
      id
      name
      profile
    }
  }
`;

export const GET_MY_GROUP_RECORDS = gql`
  query GroupRecords($groupId: Int!, $createdAt: DateTime!) {
    GroupRecords(groupId: $groupId, createdAt: $createdAt) {
      id
      createdAt
      updatedAt
      isFinish
      total
      userId
    }
  }
`;

export const GET_MY_GROUP_POSTS = gql`
  query groupPostList(
    $pagination: PaginationArgs
    $where: [GroupPostFilterArgs!]
    $sort: GroupPostSortArgs
  ) {
    GroupPostGetList(pagination: $pagination, where: $where, sort: $sort) {
      items {
        id
        content
        createdAt
        groupId
        user {
          id
          name
        }
      }
      total
      hasMore
    }
  }
`;

export const CREATE_GROUP_POST = gql`
  mutation GroupPostWrite($groupId: Int!, $content: String!) {
    GroupPostWrite(groupId: $groupId, content: $content)
  }
`;

export const UPDATE_GROUP_POST = gql`
  mutation GroupPostEdit($id: Int!, $content: String!) {
    GroupPostEdit(id: $id, content: $content)
  }
`;

export const DELETE_GROUP_POST = gql`
  mutation GroupPostDelete($id: Int!) {
    GroupPostDelete(id: $id)
  }
`;

export const GET_USER_TODO = gql`
  query TodoGetList(
    $pagination: PaginationArgs
    $where: [TodoFilterArgs!]
    $sort: TodoSortArgs
    $targetUserId: Int
  ) {
    TodoGetList(
      pagination: $pagination
      where: $where
      sort: $sort
      targetUserId: $targetUserId
    ) {
      items {
        id
        name
        repeat
        always
        categoryName
        createdAt
        times {
          id
          startAt
          endAt
        }
      }
      total
      hasMore
    }
  }
`;

export const GROUP_VERIFY = gql`
  query GroupVerify($userId: Int!, $groupId: Int!) {
    GroupVerify(userId: $userId, groupId: $groupId)
  }
`;

export const GET_USER_PROFILE = gql`
  query getUserProfile($userId: Int!) {
    getUserProfile(userId: $userId) {
      name
      profile
      introduction
      instagramId
      youtubeUrl
      twitterId
      tiktokId
      blogUrl
      coin
      donated
      rank {
        rank
      }
      groupCategory {
        subClass
      }
      records {
        createdAt
        updatedAt
        isFinish
        total
      }
    }
  }
`;

export const GET_USER_COIN_LOG_WBN = gql`
  query getWeekCoinLogAndWBN(
    $pagination: PaginationArgs
    $where: [CoinLogFilterArgs!]
    $sort: CoinLogSortArgs
    $targetUserId: Int
    $wbnWhere: [WBNBoardFilterArgs!]
  ) {
    CoinLogGetList(
      pagination: $pagination
      where: $where
      sort: $sort
      targetUserId: $targetUserId
    ) {
      items {
        id
        description
        coinDifference
        createdAt
      }
    }
    WBNBoardGetList(where: $wbnWhere) {
      items {
        worst
        best
        next
        selectedDate
        private
      }
    }
  }
`;
